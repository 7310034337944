import React from "react";

import {GoogleAnalytics} from "../../components/google/Google-Analytics";

import Footer from "../../components/footer/Footer";

import header from "../../files/svg/dlaCiekawskich/header.svg"
import Text from "../../components/text/Text";

import sensoplastyka from "../../files/svg/dlaCiekawskich/info/sensoplastyka.svg";
import czytanie from "../../files/svg/dlaCiekawskich/info/czytanie.svg";
import eksperymenty from "../../files/svg/dlaCiekawskich/info/eksperymenty.svg";
import arteterapia from "../../files/svg/dlaCiekawskich/info/arteterapia.svg";
import kodowanie from "../../files/svg/dlaCiekawskich/info/kodowanie.svg";
import Navbar from "../../components/navbar/Navbar";

class DlaCiekawskich extends React.Component {

    componentDidMount() {
        GoogleAnalytics.initGoogleAnalytics("DlaCiekawskich");
    };

    render() {
        return (
            <div>
                <Navbar/>
                <div id={"dla-ciekawskich"} className={"center-div dla-ciekawskich-background margin-bottom-200"}>
                    <div className={"container2 margin-top-200"}>
                        <div className={"row dla-ciekawskich-header-margin"}>
                            <div className={"col-10 center-div"}>
                                <img className={"dla-ciekawskich-header"} src={header} alt={"dla ciekawskich"}/>
                            </div>
                        </div>

                        <Text className={"dla-ciekawskich-height-1"} header={sensoplastyka} text={[
                            "Poprzez kolorową stymulację wpływamy na wiele aspektów rozwoju dzieci:– wspieramy rozwój zmysłów,– wspomagamy budowanie świadomości ciała i przestrzeni, dbamy o usprawnienie ruchowe– wpływamy na rozwój mowy (ośrodki w mózgu odpowiedzialne za mowę są usytuowane blisko ośrodków odpowiadających za małą motorykę – czyli pracę dłoni),– wspieramy rozwój poznawczy – opisujemy elementy świata, pokazujemy proces przemian substancji oraz związki przyczynowo – skutkowe.– wpływamy na rozwój ich kreatywności oraz twórczego myślenia, a także poszerzamy zakres ich słownictwa. Korzyści dla dzieci wynikające z uczestniczenia w  zajęciach Sensoplastyka®:– uwalnianie naturalnej ekspresji twórczej,– wspieranie samodzielności,– czerpanie satysfakcji z kreatywnego działania,– doświadczanie sukcesów w realizacji swoich pomysłów,– wspieranie aktywności we wprowadzanie zmian i ulepszeń,– wychodzenie ze schematów,– poznawanie nowych form wyrazu i działań artystycznych,– współdziałanie i wzajemna pomoc w celu osiągnięcia zamierzonych planów,– pielęgnowanie krytycznego i świadomego spojrzenia na rzeczywistość, które skutkuje innowacyjnością w działaniu, – wzmacnianie wiary we własne możliwości.Plastyka sensoryczna to mnogość faktur, tekstur oraz cała paleta ekologicznych barw."
                        ]}/>

                        <Text className={"dla-ciekawskich-height-2"} headerFloat={"right"} header={czytanie} text={[
                            "Codziennie przez 20 minut po  podwieczorku czytamy przedszkolakom bajki. Czytają pracownicy przedszkola, rodzice, rodzeństwo, dziadkowie, zaproszeni goście. Codzienne głośne czytanie jest skutecznym sposobem zbudowania bogatego zasobu słów, nauczenia poprawnej gramatykii składni, doskonalenia mowy, to także najskuteczniejszy sposób wychowania człowieka z wyobraźnią,samodzielnie myślącego i mądrego, który będzie umiał sobie radzić ze zmianami  w życiu."
                        ]}/>

                        <Text className={"dla-ciekawskich-height-3"} header={eksperymenty} text={[
                            "Dzieci to urodzeni naukowcy. Eksperymenty to połączenie świetnej zabawy i wiedzy, zdobywanej podczas odkrywania zagadek otaczającego nas świata. Naturalna ciekawość poznawcza dzieci wydaje się nie mieć granic."
                        ]}/>

                        <Text className={"dla-ciekawskich-height-4"} headerFloat={"right"} header={arteterapia} text={[
                            "Zabawy artystyczne na bazie arteterapii. Są to nietypowe zajęcia plastyczne, które poruszają wyobraźnię dziecka, przenoszą je w bezkresny świat własnej twórczości i pomysłowości. Podczas tych zajęć najważniejsza jest twórcza zabawa. Użycie różnorodnych materiałów i technik   umożliwia rozwijanie zdolności manualnych i wyobraźni, zwiększa możliwości percepcyjnej spostrzeganie, poszerza pole wyobraźni i myślenia dziecka."
                        ]}/>

                        <Text className={"dla-ciekawskich-height-5"} header={kodowanie} text={[
                            "Dziecko w przystępny sposób uczy się logicznego myślenia i w praktyce łączy ze sobą wiedzę matematyczną z wiedzą humanistyczną. Tak jak zajęcia z muzyki, plastyki czy języka obcego – warsztaty z programowania od najmłodszych lat kształtują wrażliwość i pozwalają lepiej rozumieć otaczający nas świat."
                        ]}/>
                    </div>
                </div>
                <Footer back={true}/>
            </div>
        );
    };
}

export default DlaCiekawskich;