import React from "react";

import Contact from "./object/contact/Contact";
import LastPost from "./object/lastPost/LastPost";
import Feedback from "./object/feedback/Feedback";
import Footer from "../../components/footer/Footer";

import {GoogleAnalytics} from "../../components/google/Google-Analytics";
import Navbar from "../../components/navbar/Navbar";

class Home extends React.Component {

    componentDidMount() {
        GoogleAnalytics.initGoogleAnalytics("Home");
    };

    render() {
        return (
            <div>
                <Navbar/>
                <Contact/>
                <LastPost/>
                <Feedback/>
                <Footer back={false}/>
            </div>
        );
    };
}

export default Home;