import React from "react";

import {GoogleAnalytics} from "../../components/google/Google-Analytics";
import header from "../../files/svg/osiagniecia/header.svg";

import Footer from "../../components/footer/Footer";
import axios from "axios";
import {url_api} from "../../config/ServiceConfig";
import Navbar from "../../components/navbar/Navbar";
import Post from "../../components/post/Post";

class Osiagniecia extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            osiagnieciaList: [],
        }

        this.getOsiagniecia = this.getOsiagniecia.bind(this);
    }

    componentDidMount() {
        this.getOsiagniecia()
        GoogleAnalytics.initGoogleAnalytics("Osiagniecia");
    };

    async getOsiagniecia() {
        const request = await axios.get(url_api + "/osiagniecia")
        this.setState({osiagnieciaList: request.data.list});
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div id={"osiagniecia"} className={"center-div osiagniecia-background margin-bottom-200"}>
                    <div className={"container2 margin-top-200"}>
                        <div className={"row osiagniecia-header-margin"}>
                            <div className={"col-10 center-div"}>
                                <img className={"osiagniecia-header"} src={header} alt={"osiągnięcia"}/>
                            </div>
                        </div>

                        <div className={"row text-left osiagniecia-margin"}>
                            {this.state.osiagnieciaList.map(text => {
                                return (
                                    <Post text={text}></Post>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <Footer back={true}/>
            </div>
        );
    };
}

export default Osiagniecia;