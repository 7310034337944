import React from "react";

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {CKEditor} from '@ckeditor/ckeditor5-react'

import {error, success, url_api} from "../../config/ServiceConfig";
import axios from "axios";

class CreatorText extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            date: 0,
            html: "",
            uuid: this.props.creator.state.uuid
        }

        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
    }

    componentDidMount() {
        if (this.state.uuid != null) {
            this.edit(this.state.uuid);
        }
    };

    edit = async (uuid) => {
        const request = await axios.post(url_api + this.props.url[1], {
            key: this.props.admin.state.key,
            uuid: uuid
        }).catch(reason => {
            error("API jest offline!    (" + reason + ")");
        })

        this.setState({
            html: request.data.data.html,
            date: request.data.data.date
        });
    }

    create = async (html, date) => {
        if (date === 0) {
            error("Wybierz date!");
            return;
        }

        if (html === "") {
            error("Brak tekstu!");
            return;
        }

        console.log(this.props.key)

        const request = await axios.post(url_api + this.props.url[0], {
            key: this.props.admin.state.key,
            uuid: this.state.uuid,
            html: html,
            date: date
        }).catch(reason => {
            error("API jest offline!    (" + reason + ")");
        })

        if (request.data.status === "ok") {
            success("Poprawnie zapisano!");

            this.props.creator.setState({
                type: 0
            })
        } else {
            error("Odmowa dostepu!");
        }
    }

    render() {
        return (
            <div className={"row margin-top-50"}>
                <div className={"col-10 textCreator"}>
                    {this.state.uuid}
                    <CKEditor
                        editor={Editor}

                        config={{
                            ckfinder: {
                                uploadUrl: url_api + "/image/upload"
                            },
                            heading: {
                                options: [
                                    {model: 'paragraph', view: 'p', title: 'Tekst', class: 'ck-heading_paragraph'},
                                    {model: 'heading1', view: 'h1', title: 'Nagłówek', class: 'ck-heading_heading1'},
                                ]
                            },
                            mediaEmbed: {previewsInData: true}
                        }}

                        onChange={(event, editor) => {
                            this.setState({
                                html: editor.getData()
                            })
                        }}

                        data={this.state.html}
                    />
                </div>

                <div className={"col-10 margin-top-50"}>
                    <div className={"row"}>
                        <div className={"col-5 text-left"}>
                            <span>Data <input type={"date"}
                                              onChange={event => this.setState({date: new Date(event.target.value).getTime()})}/></span>
                        </div>
                        <div className={"col-5 text-right"}>
                            <button type={"submit"}
                                    onClick={() => this.create(this.state.html, this.state.date)}>Zapisz
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default CreatorText;