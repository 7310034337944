import React from "react";

class ContactItem extends React.Component {

    render() {
        return (
            <div className={"col-33"}>
                <div className={"row center-div contact-item-phone"}>
                    <div className={"col-3"}>
                        <img src={this.props.icon} alt={"kontakt ikonka"}/>
                    </div>
                    <div className={"col-5"}>
                        <div className={"contact-float-left contact-item-text"}>
                            <img src={this.props.header} alt={"kontakt nazwa"}/>
                            <h1>{this.props.text}</h1>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactItem;