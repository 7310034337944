import React from "react";

import Text from "../../../components/text/Text";

class ONasText extends React.Component {

    render() {
        return (
            <div className={"row"}>
                <Text className={"margin-bottom-75"} alt={"o nas"} header={null} text={[
                    "Działamy od 02.01.2012 r.",
                    "Niepubliczne Przedszkole Artystyczne w Lublińcu to przedszkole dla dzieci w wieku od 2,5 lat do 7 lat. Jako prywatna placówka opieki nad dzieckiem w wieku przedszkolnym, oferujemy pełną gamę zajęć, stymulując wszechstronny rozwój dziecka."
                ]}/>
            </div>
        );
    };
}

export default ONasText;