import React from "react";
import {Link} from "react-router-dom";

import Kredka from "./object/NavbarItem";

import logo from "../../files/svg/logo.svg";

import galeria from "../../files/svg/navbar/galeria.svg";
import dlaciekawskich from "../../files/svg/navbar/dlaciekawskich.svg";
import onas from "../../files/svg/navbar/onas.svg";
import kadra from "../../files/svg/navbar/kadra.svg";
import osiagniecia from "../../files/svg/navbar/osiagniecia.svg";
import cowtrawiepiszczy from "../../files/svg/navbar/cowtrawie.svg";
import kontakt from "../../files/svg/navbar/kontakt.svg";

class Navbar extends React.Component {

    render() {
        return (
            <div className={"row"}>
                <div className={"col-5"}>
                    <Kredka img={galeria} href={"/galeria#galeria"}/>
                    <Kredka img={dlaciekawskich} href={"/dla-ciekawskich#dla-ciekawskich"}/>
                    <Kredka img={onas} href={"/o-nas#o-nas"}/>
                    <Kredka img={kadra} href={"/kadra#kadra"}/>
                    <Kredka img={osiagniecia} href={"/osiagniecia#osiagniecia"}/>
                    <Kredka img={cowtrawiepiszczy} href={"/co-w-trawie-piszczy#co-w-trawie-piszczy"}/>
                    <Kredka img={kontakt} href={"/#kontakt"}/>
                </div>

                <div className={"col-5 center-div"}>
                    <Link to={"/"}>
                        <img className={"navbar-logo"} src={logo} alt={"logo"}/>
                    </Link>
                </div>
            </div>
        );
    }
}

export default Navbar;