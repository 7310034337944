import React from "react";

class Pearson extends React.Component {

    render() {
        return (
            <div
                className={"col-10" + (this.props.className == null ? " margin-bottom-50" : " " + this.props.className)}>
                {this.props.float === "left" ?
                    <div className={"row kadra-center-div"}>
                        <div className={"kadra-col-image"}>
                            <img className={"kadra-float-left"} src={this.props.image} alt={"osoba"}/>
                        </div>

                        <div className={"kadra-col-text kadra-center"}>
                            <div className={"kadra-float-left kadra-text-left"}>
                                <div>
                                    <img src={this.props.nameImage} alt={"nazwa"}/>
                                </div>
                                <p className={"kadra-role"}>{this.props.role}</p>

                                {this.props.text.map(text => {
                                    return (<p className={"kadra-other"}>{text}</p>);
                                })}
                            </div>
                        </div>
                    </div>
                    :
                    <div className={"row kadra-center-div"}>
                        <div className={"kadra-col-image kadra-right"}>
                            <img className={"kadra-float-right"} src={this.props.image} alt={"osoba"}/>
                        </div>

                        <div className={"kadra-col-text kadra-center"}>
                            <div className={"kadra-float-right kadra-text-right"}>
                                <div>
                                    <img src={this.props.nameImage} alt={"nazwa"}/>
                                </div>
                                <p className={"kadra-role"}>{this.props.role}</p>

                                {this.props.text.map(text => {
                                    return (<p className={"kadra-other"}>{text}</p>);
                                })}
                            </div>
                        </div>
                    </div>}
            </div>
        );
    };
}

export default Pearson;