import React from "react";
import {HashLink as Link} from 'react-router-hash-link';

class NavbarItem extends React.Component {

    render() {
        return (
            <div>
                <Link to={this.props.href}>
                    <img className={"navbar-image"} src={this.props.img} alt={"kredka"}/>
                </Link>
            </div>
        );
    };
}

export default NavbarItem;