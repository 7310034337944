import React from "react";

class FooterIcon extends React.Component {

    render() {
        return (
            <div className={"col-2"}>
                <a href={this.props.href} target={"_blank"} rel="noreferrer">
                    <img src={this.props.icon} alt={this.props.icon}/>
                </a>
            </div>
        );
    }
}

export default FooterIcon;