import React from "react";

import {GoogleAnalytics} from "../../components/google/Google-Analytics";
import Navbar from "../../components/navbar/Navbar";
import axios from "axios";
import {url_api} from "../../config/ServiceConfig";
import header from "../../files/svg/coWTrawiePiszczy/header.svg";
import Post from "../../components/post/Post";
import Footer from "../../components/footer/Footer";

class CoWTrawiePiszczy extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            postyList: [],
        }

        this.getPosty = this.getPosty.bind(this);
    }

    componentDidMount() {
        this.getPosty()
        GoogleAnalytics.initGoogleAnalytics("CoWTrawiePiszczy");
    };

    async getPosty() {
        const request = await axios.get(url_api + "/posty")
        this.setState({postyList: request.data.list});
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div id={"co-w-trawie-piszczy"} className={"center-div w-trawie-background margin-bottom-200"}>
                    <div className={"container2 margin-top-200"}>
                        <div className={"row w-trawie-header-margin"}>
                            <div className={"col-10 center-div"}>
                                <img className={"w-trawie-header"} src={header} alt={"co w trawie piszczy"}/>
                            </div>
                        </div>

                        <div className={"row text-left w-trawie-margin"}>
                            {this.state.postyList.map(text => {
                                return (
                                    <Post text={text}></Post>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <Footer back={true}/>
            </div>
        );
    };
}

export default CoWTrawiePiszczy;