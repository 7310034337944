import React from "react";

import header from "../../../../files/svg/admin/login/header.svg";
import button from "../../../../files/svg/galeria/login/button.svg";
import axios from "axios";
import {error, success, url_api} from "../../../../config/ServiceConfig";

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.login = this.login.bind(this);

        this.state = {
            password: ""
        }
    }

    async login(event) {
        event.preventDefault();

        const request = await axios.post(url_api + "/admin/login", {
            password: this.state.password
        })

        if (request.data.status === "ok") {
            success('Poprawnie zalogowana!');

            this.props.admin.setState({isLogin: true, key: this.state.password});
        } else {
            error('Nieprawidłowe hasło!');
        }
    }

    render() {
        return (
            <div>
                <div id={"login"} className={"center-div login-background height-100vh"}>
                    <div className={"container"}>
                        <div className={"row login-header-margin"}>
                            <div className={"col-10 center-div"}>
                                <img className={"login-header"} src={header} alt={"nagłówek logowania"}/>
                            </div>
                        </div>

                        <div className={"col-10"}>
                            <form onSubmit={this.login}>
                                <div className={"col-10"}>
                                    <input onChange={event => this.setState({password: event.target.value})}
                                           type={"password"} className={"login-input"}
                                           placeholder={"wprowadź hasło"}/>
                                </div>
                                <div className={"col-10"}>
                                    <img onClick={this.login} className={"login-button"} src={button}
                                         alt={"przycisk logowania"}/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default Login;