import React from 'react'

class ContactMap extends React.Component {

    render() {
        return (
            <div>
                <iframe className={"contact-map"}
                        title={"mapa google pokazująca przedszkole"}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d632.1326266401773!2d18.6818238292638!3d50.67297704863864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4710dd29e7e6ed09%3A0x805c648dd947b755!2sNiepubliczne%20Przedszkole%20Artystyczne!5e0!3m2!1spl!2spl!4v1647603599164!5m2!1spl!2spl"
                        frameBorder="0"
                        style={{border: 0}}
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                />
            </div>
        )
    }
}

export default ContactMap;