import React from "react";
import {Route, Routes} from "react-router-dom";

import "./style/scss/index.scss";

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from "./page/home/Home";
import Galeria from "./page/galeria/Galeria";
import DlaCiekawskich from "./page/dlaCiekawskich/DlaCiekawskich";
import CoWTrawiePiszczy from "./page/coWTrawiePiszczy/CoWTrawiePiszczy";
import Osiagniecia from "./page/osiagnieca/Osiagniecia";
import Kadra from "./page/kadra/Kadra";
import ONas from "./page/oNas/ONas";
import Admin from "./page/admin/Admin";

class App extends React.Component {

    render() {
        return (
            <div>
                <ToastContainer/>
                <Routes>
                    <Route exact path="/admin" element={<Admin/>}/>
                </Routes>
                <Routes>
                    <Route exact path="/home" element={<Home/>}/>
                    <Route exact path="/galeria" element={<Galeria/>}/>
                    <Route exact path="/dla-ciekawskich" element={<DlaCiekawskich/>}/>
                    <Route exact path="/co-w-trawie-piszczy" element={<CoWTrawiePiszczy/>}/>
                    <Route exact path="/osiagniecia" element={<Osiagniecia/>}/>
                    <Route exact path="/kadra" element={<Kadra/>}/>
                    <Route exact path="/o-nas" element={<ONas/>}/>
                    <Route exact path="/" element={<Home/>}/>
                </Routes>
            </div>
        );
    }
}

export default App;
