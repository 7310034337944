import React from "react";

import Text from "../../../components/text/Text";

import rekrutacja from "../../../files/svg/oNas/text/rekrutacja.svg";
import button from "../../../files/svg/oNas/button.svg";

class RekrutacjaText extends React.Component {

    render() {
        return (
            <div>
                <Text className={"margin-bottom-75"} alt={"rekrutacja"} header={rekrutacja} text={[
                    "Rekrutacja w naszym przedszkolu trwa cały rok.",
                    "Istnieje możliwość zapisania dziecka do wybranej grupy wiekowej pod warunkiem dostępności miejsca.",
                    "Gwarancją rezerwacji miejsca jest podpisanie umowy oraz dokonanie opłaty wpisowe",
                ]}/>
                <a rel="noreferrer" target={"_blank"} href={"https://l.facebook.com/l.php?u=https%3A%2F%2Fweb-proxy.livekid.com%2F%2Fapply%2F22357200%2F08131b17-c2ab-4296-ad91-200595b95aba%3Fregion%3Dpl%26lang%3Dpl%26fbclid%3DIwAR2BaflBb9HpSCRaWvJ1vtjgfyymUMTYCCUdbh2BgEcbdNlIr0xPjwqea3o&h=AT3omc38QuslvUJ1CUhNmfxvPcFWsRu1z6U4eCYxAoD4SX2v_ynWRhufUt-tNzjn2G-igp7LTSO178Yqy6C02gOE_setp5dTE0zIjdigOYpbErShdYGNkFmI41wlkDfJNMai0g"}>
                    <img alt={"rekrutacja"} src={button} className={"o-nas-rekrutacja-button"}/>
                </a>
            </div>
        );
    };
}

export default RekrutacjaText;