import React from "react";

import {GoogleAnalytics} from "../../components/google/Google-Analytics";

import Galeria2 from "./object/galeria/Galeria";
import GaleriaLogin from "./object/galeriaLogin/GaleriaLogin";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";

class Galeria extends React.Component {

    componentDidMount() {
        GoogleAnalytics.initGoogleAnalytics("Galeria");
    };

    render() {
        return (
            <div>
                <Navbar/>
                <Galeria2/>
                <GaleriaLogin/>
                <Footer back={false}/>
            </div>
        );
    };
}

export default Galeria;