import React from "react";

import Login from "./object/login/Login";
import Menu from "./object/menu/Menu";

class Admin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogin: false,
            key: ""
        }
    }

    render() {
        return (
            <div>
                {this.state.isLogin ? <Menu admin={this}/> : <Login admin={this}/>}
            </div>
        );
    };
}

export default Admin;