import React from "react";

import axios from "axios";
import {url_api} from "../../../../../config/ServiceConfig";

import FeedbackItem from "./FeedbackItem";

import left from "../../../../../files/svg/feedback/left.svg";
import right from "../../../../../files/svg/feedback/right.svg";

class FeedbackCarousel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            feedbackList: [],
            left: 0,
            right: 1,
            feedbackShow: [],
        }

        this.getFeedback = this.getFeedback.bind(this);
        this.move = this.move.bind(this);
    }

    componentDidMount() {
        this.getFeedback()
    }

    async getFeedback() {
        const request = await axios.get(url_api + "/reviews")

        this.setState({feedbackList: request.data.list});
        this.setState({feedbackShow: [request.data.list[0], request.data.list[1]]});
    }

    move(direction) {
        let left = this.state.left;
        let right = this.state.right;
        const list = this.state.feedbackList;

        if (direction === "left") {
            if (left === 0) {
                left = (list.length - 1);
            } else {
                left--;
            }

            if (right === 0) {
                right = (list.length - 1);
            } else {
                right--;
            }
        } else if (direction === "right") {
            if (right === (list.length - 1)) {
                right = 0;
            } else {
                right++;
            }

            if (left === (list.length - 1)) {
                left = 0;
            } else {
                left++;
            }
        } else {
            return;
        }

        this.setState({feedbackShow: [list[left], list[right]]});

        this.setState({left: left})
        this.setState({right: right})
    }

    render() {
        return (
            <div>
                <div className={"row"}>
                    {this.state.feedbackShow.map(value => {
                        return (<FeedbackItem value={value}/>);
                    })}
                </div>
                <div className={"row"}>
                    <div className={"col-10"}>
                        <div className={"float-left margin-left-100"}>
                            <img onClick={() => this.move("left")} src={left} alt={left}/>
                        </div>
                        <div className={"float-right margin-right-100"}>
                            <img onClick={() => this.move("right")} src={right} alt={left}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default FeedbackCarousel;