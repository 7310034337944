import React from "react";

import header from "../../../../files/svg/galeria/login/header.svg";
import button from "../../../../files/svg/galeria/login/button.svg";
import axios from "axios";
import {error, success, url_api} from "../../../../config/ServiceConfig";
import {GoogleAnalytics} from "../../../../components/google/Google-Analytics";

class GaleriaLogin extends React.Component {

    constructor(props) {
        super(props);

        this.login = this.login.bind(this);

        this.state = {
            password: ""
        }
    }

    async login(event) {
        event.preventDefault();

        const request = await axios.post(url_api + "/galeria", {
            password: this.state.password
        })

        if (request.data.status === "ok") {
            success('Trwa przekierowanie!');

            await GoogleAnalytics.initGoogleAnalytics("GaleriaGoogle");

            window.location.href = request.data.url.toString();
        } else {
            error('Nieprawidłowe hasło!');
        }
    }

    render() {
        return (
            <div>
                <div className={"center-div galeria-login-background"}>
                    <div className={"container margin-bottom-250 margin-top-250"}>
                        <div className={"row"}>
                            <div className={"col-10 center-div"}>
                                <img className={"galeria-login-header"} src={header} alt={"logowanie do galerii"}/>
                            </div>
                            <div className={"col-10"}>
                                <form onSubmit={this.login}>
                                    <div className={"col-10"}>
                                        <input onChange={event => this.setState({password: event.target.value})}
                                               type={"password"} className={"galeria-login-input"}
                                               placeholder={"wprowadź hasło"}/>
                                    </div>
                                    <div className={"col-10"}>
                                        <img onClick={this.login} className={"galeria-login-button"} src={button}
                                             alt={"przycisk logowania do galerii"}/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default GaleriaLogin;