import ReactGA from "react-ga4";

const GoogleAnalytics = {};

GoogleAnalytics.initGoogleAnalytics = async (name) => {
    ReactGA.initialize([
        {
            trackingId: 'G-SCGM8QSKQV',
            gaOptions: {
                name: 'Analytics'
            }
        },
        {
            trackingId: 'UA-223387598-1',
            gaOptions: {
                name: 'GoogleADS'
            }
        }
    ]);
    ReactGA.pageview("/" + name, ['Analytics']);
};

export {GoogleAnalytics};