import React from "react";
import {GoogleAnalytics} from "../../components/google/Google-Analytics";
import header from "../../files/svg/kadra/header.svg";
import Footer from "../../components/footer/Footer";
import Pearson from "./object/Pearson";

import asia from "../../files/svg/kadra/pearson/asia.svg";
import asiaText from "../../files/svg/kadra/pearson/asiaText.svg";

import malwina from "../../files/svg/kadra/pearson/malwina.svg";
import malwinaText from "../../files/svg/kadra/pearson/malwinaText.svg";

import przemek from "../../files/svg/kadra/pearson/przemek.svg";
import przemekText from "../../files/svg/kadra/pearson/przemekText.svg";

import marysia from "../../files/svg/kadra/pearson/marysia.svg";
import marysiaText from "../../files/svg/kadra/pearson/marysiaText.svg";

import maria from "../../files/svg/kadra/pearson/maria.svg";
import mariaText from "../../files/svg/kadra/pearson/mariaText.svg";

import natalia from "../../files/svg/kadra/pearson/natalia.svg";
import nataliaText from "../../files/svg/kadra/pearson/nataliaText.svg";

import joanna from "../../files/svg/kadra/pearson/joanna.svg";
import joannaText from "../../files/svg/kadra/pearson/joannaText.svg";

import Navbar from "../../components/navbar/Navbar";

class Kadra extends React.Component {

    componentDidMount() {
        GoogleAnalytics.initGoogleAnalytics("Kadra");
    };

    render() {
        return (
            <div>
                <Navbar/>
                <div id={"kadra"} className={"center-div kadra-background margin-bottom-200"}>
                    <div className={"container2 margin-top-200"}>
                        <div className={"row kadra-header-margin"}>
                            <div className={"col-10"}>
                                <img className={"kadra-header"} src={header} alt={"kadra"}/>
                            </div>
                        </div>

                        <Pearson float={"left"} image={asia} name={"Joanna Cieślik"} nameImage={asiaText}
                                 role={"Właściciel i Dyrektor"} text={[
                            "nauczyciel plastyki",
                            "oligofrenopegagog",
                            "arteterapeuta",
                            "terapeuta kinezjolog",
                            "trener I stopnia Sensoplastyka®",
                            "certyfikowany trener Plastyka™ Ekspresywna",
                            "właściel  Galeria i Pracownia Artystyczna Joanna Cieślik",
                        ]}/>

                        <Pearson float={"right"} image={malwina} name={"Malwina Kazek"} nameImage={malwinaText}
                                 role={"Nauczyciel edukacji przedszkolnej i wczesnoszkolnej"} text={[
                            "pedagog",
                            "trener I stopnia Sensoplastyka®",
                        ]}/>

                        <Pearson float={"left"} image={przemek} name={"Przemysław Ploska"} nameImage={przemekText}
                                 role={"Nauczyciel edukacji przedszkolnej i wczesnoszkolnej"} text={[
                            "j.angielski",
                            "szachy",
                            "kodowanie na dywanie",
                            "programowanie robotów Ozobot",
                        ]}/>

                        <Pearson float={"right"} image={marysia} name={"Maria Kasznia"} nameImage={marysiaText}
                                 role={"pracownik gospodarczy"} text={[]}/>

                        <Pearson float={"left"} image={maria} name={"Maria Piotrowska"} nameImage={mariaText}
                                 role={"neurologopeda"} text={[]}/>

                        <Pearson float={"right"} image={natalia} name={"Natalia Krawiec"} nameImage={nataliaText}
                                 role={"psycholog"} text={[]}/>

                        <Pearson className={"margin-bottom-200"} float={"left"} image={joanna} name={"Joanna Pułkownik"}
                                 nameImage={joannaText}
                                 role={"Instruktor Zumba Kids + Kids Jr"} text={[]}/>
                    </div>
                </div>
                <Footer back={true}/>
            </div>
        );
    };
}

export default Kadra;