import React from "react";

import instagram from "../../files/svg/icon/instagram.svg";
import facebook from "../../files/svg/icon/facebook.svg";

import FooterIcon from "./object/FooterIcon";

class Footer extends React.Component {

    render() {
        return (
            <div>
                <div className={"footer-background" + (this.props.back === false ? "" : "-back") + " center-div"}/>
                <div className={"footer-background2" + (this.props.back === false ? "" : "-back") + " center-div"}>
                    <div className={"container" + (this.props.back === false ? "" : " margin-top-100")}>
                        <div className={"row center-div"}>
                            <FooterIcon href={"https://www.instagram.com/artystyczne.przedszkole/"} icon={instagram}/>
                            <FooterIcon
                                href={"https://www.facebook.com/Niepubliczne-Przedszkole-Artystyczne-w-Lubli%C5%84cu-Joanna-Cie%C5%9Blik-280541801994296"}
                                icon={facebook}/>
                            <div className={"col-10 margin-top-25"}>
                                <h1 className={"footer-h1"}>© 2022 Niepubliczne Przedszkole Artystyczne</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;