import React from "react";

import Text from "../../../components/text/Text";

import oferta from "../../../files/svg/oNas/text/oferta.svg";

class OfertaText extends React.Component {

    render() {
        return (
            <div>
                <Text className={"margin-bottom-75"} alt={"oferta"} header={oferta} text={[
                    "Jako przedszkole artystyczne, realizujemy programy wychowania przez sztukę. W codziennej pracy wychowawczo – dydaktycznej wykorzystamy metody wielostronnego kontaktu z muzyką, tańcem, teatrem, malarstwem, rysunkiem, rzeźbą dzięki czemu zainspirujemy dziecko do działania, doświadczania i przeżywania, a tym samym budowania własnego systemu wartości.",
                    "",
                    "Jesteśmy małym przedszkolem i dzięki małej grupie możemy podejść indywidualnie do każdego dziecka. Panuje u nas domowa atmosfera dzięki której dzieci czują się jak w domu. Posiadamy własny plac zabaw na którym dzieci mogą się bezpiecznie bawić pod bacznym okiem naszej kadry.",
                    "",
                    "W ramach czesnego przedszkole zapewnia:",
                    "* codzienne zajęcia dydaktyczne, uwzględniające realizację podstawy programowej MEN,",
                    "* zajęcia dodatkowe,",
                    "* wyśjcia do kina, na przedstawienia teatralne,",
                    "* prezenty świąteczne.",
                    "* Logopedę",
                    "* Psychologa",
                ]}/>
            </div>
        );
    };
}

export default OfertaText;