import React from "react";

import star from "../../../../../files/svg/feedback/star.svg";


class FeedbackItem extends React.Component {

    constructor(props) {
        super(props);

        this.createStar = this.createStar.bind(this);
    }

    createStar() {
        const elements = [];
        for (let i = 0; i < this.props.value.rating; i++) {
            elements.push(<img src={star} alt={this.props.value.author_name}/>);
        }
        return elements;
    }

    render() {
        return (
            <div className={"col-5 height-450"}>
                <div className={"feedback-item"}>
                    <div className={"feedback-item-background"}>
                        <div className={"feedback-item-text"}>
                            <h1 className={"feedback-item-h1"}>{this.props.value.text}</h1>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"feedback-item-triangle"}/>
                        <div className={"margin-left-15 margin-top-15"}>
                            {this.createStar()}
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"margin-left-55 margin-top-10 feedback-item-author"}>
                            <p>{this.props.value.author_name}</p>
                        </div>
                    </div>

                </div>
            </div>
        );
    };
}

export default FeedbackItem;