import React from "react";

import header from "../../../../files/svg/contact/header.svg";
import header1 from "../../../../files/svg/contact/header1.svg";
import send from "../../../../files/svg/contact/send.svg";

import locationIcon from "../../../../files/svg/icon/location.svg";
import locationHeader from "../../../../files/svg/contact/location.svg";

import emailIcon from "../../../../files/svg/icon/email.svg";
import emailHeader from "../../../../files/svg/contact/email.svg";

import telephoneIcon from "../../../../files/svg/icon/telephone.svg";
import telephoneHeader from "../../../../files/svg/contact/telephone.svg";

import ContactMap from "./object/ContactMap";

import axios from "axios";
import {error, success, url_api} from "../../../../config/ServiceConfig";
import ContactItem from "./object/ContactItem";

class Contact extends React.Component {

    constructor(property) {
        super(property);

        this.state = {
            name: "",
            email: "",
            message: ""
        }

        this.sendMessage = this.sendMessage.bind(this);
    }

    async sendMessage() {
        const name = this.state.name;
        const email = this.state.email;
        const message = this.state.message;

        if (name === "" || email === "" || message === "") {
            error('Aby wysłać wiadomość musisz poprawnie wypełnić formularz!');
            return;
        }

        this.setState({name: ""})
        this.setState({email: ""})
        this.setState({message: ""})

        Array.from(document.querySelectorAll("input")).forEach(input => (input.value = ""));
        Array.from(document.querySelectorAll("textarea")).forEach(textarea => (textarea.value = ""));

        const response = await axios.post(url_api + "/send", {
            contactName: name,
            contactEmail: email,
            contactMessage: message
        });

        if (response.data.status === "ok") {
            success('Poprawnie wysłano wiadomość!');
        }

    }

    render() {
        return (
            <div className={"margin-top-150"}>
                <div className={"center-div contact-background"}>
                    <div className={"container margin-bottom-200 margin-top-150"}>
                        <div className={"row contact-bottom"}>
                            <div className={"col-10"}>
                                <div id={"kontakt"} className={"margin-bottom-50"}/>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-10 center-div"}>
                                <img className={"contact-header"} src={header} alt={"kontakt"}/>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-5"}>
                                <div className={"center-div"}>
                                    <img className={"margin-top-50 margin-bottom-15"} src={header1} alt={"kontakt"}/>
                                </div>
                                <form>
                                    <input id={"name"} placeholder={"imię i nazwisko"} className={"contact-input-field"}
                                           onChange={event => this.setState({name: event.target.value})}/>
                                    <input id={"email"} placeholder={"adres e-mail"} className={"contact-input-field"}
                                           onChange={event => this.setState({email: event.target.value})}/>
                                    <textarea id={"text"} placeholder={"wiadomość"} className={"contact-input-text"}
                                              onChange={event => this.setState({message: event.target.value})}/>
                                </form>

                                <img onClick={() => this.sendMessage()} className={"margin-top-15 contact-sendButton"}
                                     src={send} alt={"wyślij wiadomość"}/>
                            </div>
                            <div className={"col-5"}>
                                <div className={"center-div margin-top-80"}>
                                    <ContactMap/>
                                </div>
                            </div>
                        </div>

                        <div className={"row margin-top-60"}>
                            <ContactItem icon={emailIcon} header={emailHeader} text={"galeria_joanna@wp.pl"}/>
                            <ContactItem icon={locationIcon} header={locationHeader}
                                         text={"ul. Częstochowska 2 (obok spółdzielni Strzecha) wejście do przedszkola od strony tarasu z tyłu bloku 42-700 Lubliniec"}/>
                            <ContactItem icon={telephoneIcon} header={telephoneHeader} text={"881 699 202"}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;