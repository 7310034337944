import React from "react";

import header from "../../../../files/svg/feedback/header.svg";

import FeedbackCarousel from "./object/FeedbackCarousel";

class Feedback extends React.Component {

    render() {
        return (
            <div>
                <div className={"center-div feedback-background"}>
                    <div className={"container margin-bottom-250"}>
                        <div className={"row"}>
                            <div className={"col-10"}>
                                <img className={"margin-top-200"} src={header} alt={"opinia"}/>
                            </div>
                        </div>

                        <FeedbackCarousel/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Feedback;