import React from "react";

import header from "../../../../files/svg/coWTrawiePiszczy/header.svg";
import axios from "axios";
import {url_api} from "../../../../config/ServiceConfig";
import Post from "../../../../components/post/Post";

class LastPost extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            postyLatestList: [],
        }

        this.getPostyLatest = this.getPostyLatest.bind(this);
    }

    componentDidMount() {
        this.getPostyLatest()
    };

    async getPostyLatest() {
        const request = await axios.get(url_api + "/posty/latest")
        this.setState({postyLatestList: request.data.list});
    }

    render() {
        return (
            <div>
                <div className={"center-div w-trawie-index-background"}>
                    <div className={"container2"}>
                        <div className={"row"}>
                            <div className={"col-10 center-div"}>
                                <img className={"w-trawie-index-img"} src={header} alt={"co w trawie piszczy"}/>
                            </div>
                        </div>

                        <div className={"row margin-bottom-100"}>
                            <div className={"col-10 w-trawie-index-top"}>
                                {this.state.postyLatestList.map(text => {
                                    return (
                                        <Post text={text}></Post>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LastPost;