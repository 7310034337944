import React from "react";

import logo from '../../files/svg/post/logo.svg';
import date from '../../files/svg/post/date.svg';

class Post extends React.Component {

    convertText = () => {
        let text = this.props.text.html;

        let img = text.split("<img");
        if (typeof img[1] != "undefined") {
            img = img[1].split("\">")[0];
            img = "<img" + img + "\">";
            text = text.replace(img, "");
        } else {
            img = undefined;
        }

        return {text: text, image: img}
    }

    getImage = (image) => {
        return (
            <div className={"col-10"}>
                <div className={"post-image"} dangerouslySetInnerHTML={{__html: image}}/>
            </div>
        );
    }

    render() {
        const convertText = this.convertText();
        return (
            <div className={"row center-div width-100"}>
                <div className={"col-10 center-div"}>
                    <div
                        className={typeof convertText.image != "undefined" ? "post-background-image" : "post-background"}>
                        <div className={"row post-frame text-left"}>
                            <div className={"col-08 post-center-1"}>
                                <img src={logo} alt={"logo"}/>
                            </div>
                            <div className={"col-92 center-div-vertical"}>
                                <div className={"row margin-left-10"}>
                                    <div className={"col-10"}>
                                        <span className={"post-name"}>Niepubliczne Przedszkole Artystyczne</span>
                                    </div>
                                    <div className={"col-10 center-div-vertical"}>
                                        <img className={"post-date-icon"} src={date} alt={"data"}/>
                                        <span className={"margin-left-5 post-date-text"}>{this.props.text.date}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-10 margin-top-10"}>
                                <div className={"textCreator"}>
                                    <div dangerouslySetInnerHTML={{__html: convertText.text}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    typeof convertText.image != "undefined" ? this.getImage(convertText.image) : ""
                }
            </div>
        );
    }
}

export default Post;