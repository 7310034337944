import React from "react";

import {error, url_api} from "../../config/ServiceConfig";
import axios from "axios";

class ListText extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            list: [],
        }

        this.getList = this.getList.bind(this);
        this.onEdit = this.onEdit.bind(this);
    }

    componentDidMount() {
        this.getList()
    };

    getList = async () => {
        const request = await axios.post(url_api + this.props.url, {
            key: this.props.admin.state.key,
        }).catch(reason => {
            error("API jest offline!    (" + reason + ")");
        })

        this.setState({list: request.data.list});
    }

    onEdit = (uuid) => {
        this.props.creator.changeType(3)
        this.props.creator.setState({
            uuid: uuid
        });
    }

    render() {
        return (
            <div className={"row margin-top-50"}>
                <div className={"col-10"}>
                    <div className={"row"}>
                        {this.state.list.map(text => {
                            return (
                                <div className={"col-10 margin-bottom-20"}>
                                    <div className={"row"}>
                                        <div className={"col-7 text-left"}>
                                            <span>{text.title}</span>
                                        </div>
                                        <div className={"col-2 text-center"}>
                                            <span>{text.date}</span>
                                        </div>
                                        <div className={"col-1 text-center"}>
                                            <button type={"submit"}
                                                    onClick={() => this.onEdit(text.uuid)}>Edytuj
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };
}

export default ListText;