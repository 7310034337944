import React from "react";
import CreatorText from "../../../../components/text/CreatorText";
import ListText from "../../../../components/text/ListText";

class PanelPosty extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            type: 0
        }

        this.changeType = this.changeType.bind(this);
    }

    changeType = (type) => {
        this.setState({
            type: type,
            uuid: null
        })
    }

    render() {
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-1"}>
                        <button onClick={() => this.changeType(1)}>Nowy</button>
                    </div>
                    <div className={"col-1"}>
                        <button onClick={() => this.changeType(2)}>Lista</button>
                    </div>
                </div>
                {this.state.type === 1 ?
                    <CreatorText creator={this} admin={this.props.admin} url={["/admin/update/posty"]}/> : ""}
                {this.state.type === 2 ?
                    <ListText creator={this} admin={this.props.admin} url={"/admin/get/posty"}/> : ""}
                {this.state.type === 3 ?
                    <CreatorText creator={this} admin={this.props.admin}
                                 url={["/admin/update/posty", "/admin/get/post"]}/> : ""}
            </div>
        );
    };
}

export default PanelPosty;