import React from "react";
import PanelOsiagniecia from "../panel/PanelOsiagniecia";
import PanelPosty from "../panel/PanelPosty";

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            type: 0
        }

        this.changeType = this.changeType.bind(this);
    }

    changeType = (type) => {
        this.setState({type: type})
    }

    render() {
        return (
            <div className={"center-div"}>
                <div className={"container2 margin-top-50"}>
                    <div className={"row"}>
                        <div className={"col-10 panel-title-height"}>
                            <h1 className={"panel-title-text"}>Panel administracyjny {
                                this.state.type === 0 ? "" :
                                    this.state.type === 1 ? " (Osiagniecia)" :
                                        this.state.type === 2 ? " (Posty)" : ""}</h1>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-5"}>
                            <button onClick={() => this.changeType(1)}>Osiagniecia</button>
                        </div>
                        <div className={"col-5"}>
                            <button onClick={() => this.changeType(2)}>Posty</button>
                        </div>
                        <hr className={"panel-hr"}/>
                    </div>

                    {
                        this.state.type === 0 ? "" :
                            this.state.type === 1 ? <PanelOsiagniecia admin={this.props.admin}/> :
                                this.state.type === 2 ? <PanelPosty admin={this.props.admin}/> : ""
                    }
                </div>
            </div>
        );
    };
}

export default Login;