import React from "react";

import Text from "../../../components/text/Text";

import cennik from "../../../files/svg/oNas/text/cennik.svg";

class CennikText extends React.Component {

    render() {
        return (
            <div>
                <Text className={"margin-bottom-75"} alt={"cennik"} header={cennik} text={[
                    "– wpisowe: 250 zł*,",
                    "– czesne: 250 zł,",
                    "wyżywienie „SZOPA” Sebastian Piotrowski – śniadanie: 3,50 zł – podwieczorek: 2,50 zł – obiad: 11,00 zł",
                    "",
                    "*wpisowe opłata jednorazowa, bezzwrotna celem rezerwacji miejsca w Przedszkolu z przeznaczeniem na fundusz rozwoju placówki",
                ]}/>
            </div>
        );
    };
}

export default CennikText;
