import React from "react";

import Text from "../../../components/text/Text";

import godziny from "../../../files/svg/oNas/text/godziny.svg";

class GodzinyText extends React.Component {

    render() {
        return (
            <div>
                <Text className={"margin-bottom-200"} alt={"godziny"} header={godziny} text={[
                    "Godziny otwarcia",
                    "poniedziałek – piątek w godz. 6.15 – 17.15",
                    "24 grudnia i 31 grudnia przedszkole jest zamknięte.",
                ]}/>
            </div>
        );
    };
}

export default GodzinyText;