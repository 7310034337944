import React from "react";

import Text from "../../../components/text/Text";
import ZajeciaIcon from "./other/ZajeciaIcon";

import zajecia from "../../../files/svg/oNas/text/zajecia.svg";

import plastyka from "../../../files/svg/oNas/zajecia/plastyka.svg";
import czytanie from "../../../files/svg/oNas/zajecia/czytanie.svg";
import angielski from "../../../files/svg/oNas/zajecia/angielski.svg";

import rytmikas from "../../../files/svg/oNas/zajecia/rytmika.svg";
import sensoryka from "../../../files/svg/oNas/zajecia/sensoryka.svg";
import szachy from "../../../files/svg/oNas/zajecia/szachy.svg";
import kodowanie from "../../../files/svg/oNas/zajecia/kodowanie.svg";
import zumba from "../../../files/svg/oNas/zajecia/zumba.svg";
import spiewowo from "../../../files/svg/oNas/zajecia/spiewowo.svg";

import eksperymenty from "../../../files/svg/oNas/zajecia/eksperymenty.svg";
import sensoplastyka from "../../../files/svg/oNas/zajecia/sensoplastyka.svg";
import kuchcikowo from "../../../files/svg/oNas/zajecia/kuchcikowo.svg";
import arteterapia from "../../../files/svg/oNas/zajecia/arteterapia.svg";

class ZajeciaText extends React.Component {

    render() {
        return (
            <div className={"row"}>
                <Text className={"margin-bottom-35"} alt={"zajecia"} header={zajecia} text={[
                    "W codziennej pracy dydaktyczno-wychowawczej realizujemy:",
                    "* Podstawę Programową Wychowania Przedszkolnego",
                    "* Program „Planeta Dzieci” wyd. WSiP",
                    "* Planeta dzieci to wyjątkowe miejsce dla wszystkich, dla których ważne są prawda, dobro, piękno, szacunek do ludzi i przyrody oraz idee kształtujące pozytywny stosunek do świata. Wartości, które łączą ludzi, tworzą najlepsze środowisko do wszechstronnego i kreatywnego rozwoju, dają dobry start w dorosłe życie. Zapraszamy do udziału w niezwykłej przygodzie, która zaczyna się w przedszkolu.",
                    "* wychowanie w duchu wartości,",
                    "* nowoczesna odsłona Metody Dobrego Startu,",
                    "* atrakcyjne zabawy z kodowaniem,",
                    "* przestrzeń nowych możliwości dzięki materiałom multimedialnym i aplikacji Multiplaneta.",
                ]}/>

                <div className={"col-10 margin-bottom-35"}>
                    <h3 className={"o-nas-h3"}>Staramy się optymalnie organizować czas dla dzieci poprzez:</h3>
                </div>

                <div className={"col-10 margin-bottom-50 o-nas-icon"}>
                    <ZajeciaIcon image={[plastyka, czytanie, angielski]}/>
                    <ZajeciaIcon image={[rytmikas, sensoryka, szachy, kodowanie, zumba, spiewowo]}/>
                    <ZajeciaIcon image={[eksperymenty, sensoplastyka, kuchcikowo, arteterapia]}/>
                </div>

                <Text header={null} text={[
                    "W ramach zajęć organizujemy ciekawe wycieczki edukacyjne. Co pewien czas chodzimy do kina, na przedstawienia teatralne. Wysyłamy też naszych podopiecznych na różne konkursy, na których mogą zaprezentować swoje talenty."
                ]}/>
            </div>
        );
    };
}

export default ZajeciaText;