import React from "react";

import {GoogleAnalytics} from "../../components/google/Google-Analytics";
import header from "../../files/svg/oNas/header.svg";

import ZajeciaText from "./object/ZajeciaText";
import OfertaText from "./object/OfertaText";
import ONasText from "./object/ONasText";
import RekrutacjaText from "./object/RekrutacjaText";
import CennikText from "./object/CennikText";
import GodzinyText from "./object/GodzinyText";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";

class ONas extends React.Component {

    componentDidMount() {
        GoogleAnalytics.initGoogleAnalytics("ONas");
    };

    render() {
        return (
            <div>
                <Navbar/>
                <div id={"o-nas"} className={"center-div o-nas-background margin-bottom-200"}>
                    <div className={"container2 margin-top-200"}>
                        <div className={"row margin-bottom-75"}>
                            <div className={"col-10"}>
                                <img className={"o-nas-header2"} src={header} alt={"o nas"}/>
                            </div>
                        </div>

                        <ONasText/>
                        <OfertaText/>
                        <ZajeciaText/>
                        <RekrutacjaText/>
                        <CennikText/>
                        <GodzinyText/>
                    </div>
                </div>

                <Footer back={true}/>
            </div>
        );
    };
}

export default ONas;