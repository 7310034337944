import React from "react";

import header from "../../../../files/svg/galeria/header.svg";
import Photo from "./object/Photo";

import zdj1 from "../../../../files/png/galeria/photo/1.jpg";
import zdj2 from "../../../../files/png/galeria/photo/2.jpg";
import zdj3 from "../../../../files/png/galeria/photo/3.jpg";
import zdj4 from "../../../../files/png/galeria/photo/4.jpg";
import zdj5 from "../../../../files/png/galeria/photo/5.jpg";
import zdj6 from "../../../../files/png/galeria/photo/6.jpg";
import zdj7 from "../../../../files/png/galeria/photo/7.jpg";
import zdj8 from "../../../../files/png/galeria/photo/8.jpg";
import zdj9 from "../../../../files/png/galeria/photo/9.jpg";
import zdj10 from "../../../../files/png/galeria/photo/10.jpg";
import zdj11 from "../../../../files/png/galeria/photo/11.jpg";
import zdj12 from "../../../../files/png/galeria/photo/12.jpg";
import zdj13 from "../../../../files/png/galeria/photo/13.jpg";
import zdj14 from "../../../../files/png/galeria/photo/14.jpeg";

class Galeria extends React.Component {

    render() {
        return (
            <div>
                <div className={"margin-top-80"}>
                    <div id={"galeria"} className={"center-div galeria-header-background"}>
                        <div className={"container galeria-header-margin"}>
                            <div className={"row"}>
                                <div className={"col-10"}>
                                    <img className={"galeria-header"} src={header} alt={"galeria"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"center-div galeria-background"}>
                        <div className={"container galeria-margin"}>
                            <div className={"row"}>
                                <Photo image={zdj1}/>
                                <Photo image={zdj2}/>
                                <Photo image={zdj3}/>
                                <Photo image={zdj4}/>
                                <Photo image={zdj5}/>
                                <Photo image={zdj6}/>
                                <Photo image={zdj7}/>
                                <Photo image={zdj8}/>
                                <Photo image={zdj9}/>
                                <Photo image={zdj10}/>
                                <Photo image={zdj11}/>
                                <Photo image={zdj12}/>
                                <Photo image={zdj13}/>
                                <Photo image={zdj14}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default Galeria;