import React from "react";

class Text extends React.Component {

    render() {
        return (
            <div
                className={"col-10" + (this.props.className == null ? " margin-bottom-50" : " " + this.props.className)}>
                {this.props.header == null ? "" :
                    <div className={"text-" + (this.props.headerFloat === "right" ? "right" : "left")}><img
                        className={"text-header"} src={this.props.header} alt={this.props.alt}/></div>}
                {this.props.text.map(text => {
                    return (text === "" ? <div className={"margin-bottom-20"}/> :
                        <div><p className={"text-text"}>{text}</p></div>);
                })}
            </div>
        );
    };
}

export default Text;