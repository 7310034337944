import React from "react";

class Galeria extends React.Component {

    render() {
        return (
            <div className={"col-5"}>
                <img className={"galeria-photo"} src={this.props.image} alt={this.props.image}/>
            </div>
        );
    };
}

export default Galeria;