import React from "react";

class ZajeciaIcon extends React.Component {

    render() {
        return (
            <div>
                <div className={"row stick-bottom center-div"}>
                    {this.props.image.map(icon => {
                        return (
                            <div className={"o-nas-margin"}>
                                <img src={icon} alt={icon}/>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };
}

export default ZajeciaIcon;